import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down(501)]: {
        title: {
          fontSize: 15,
        },
      },
    },
    helperText: {
      color: 'red',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    inputText: {
      [theme.breakpoints.down(501)]: {
        fontSize: 10,
      },
    },
    title: {
      width: `100%`,
      color: `#074F71`,
      textAlign: `center`,
      margin: 0,
      fontSize: 18,
      marginTop: 15,
      [theme.breakpoints.down(501)]: {
        fontSize: 14,
      },
    },
    subtitle: {
      width: `100%`,
      color: `#074F71`,
      textAlign: `center`,
      marginTop: 0,
      fontSize: 14,
      [theme.breakpoints.down(501)]: {
        fontSize: 11,
      },
    },
    residenceSubtitle: {
      width: `100%`,
      color: `#074F71`,
      textAlign: `left`,
      paddingLeft: 12,
      paddingTop: 10,
      marginTop: 0,
      fontSize: 18,
    },
    formProperties: {
      '& label': {
        [theme.breakpoints.down(501)]: {
          fontSize: 13,
        },
        color: `#074f71`,
      },
      '& fieldset': {
        borderColor: `#074f71`,
      },
      [theme.breakpoints.down(501)]: {
        '& input': {
          fontSize: 13,
        },
      },
    },
  })
);
export default useStyles;
