import * as rutHelpers from 'rut-helpers';
import * as Yup from 'yup';
import { mixed, string, object, array } from 'yup';
import { ICountryData } from '../../../types';
import { rutValidate } from '../../../utils/rutHelpers';
import { postcodeValidator } from 'postcode-validator';

export const PersonalSerialNumberSchema = object({
  serialNumber: string()
    .required('Campo requerido')
    .matches(
      /^[a-zA-Z0-9]{9,}$/,
      'Debe tener al menos 9 caracteres y contener solo números o letras'
    ),
  identificationValue: string().test('rutValidation', function (value) {
    const rut = rutHelpers.rutClean(value || '');
    if (!rutValidate(rut)) {
      const { path, createError } = this;
      return createError({ path, message: 'Ingresar un rut correcto' });
    } else {
      return true;
    }
  }),
});

export const PersonalInformationSchema = object({
  firstName: string()
    .required('Campo requerido')
    .matches(
      /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/,
      'Información Inválida. Ingresa solo letras por favor.'
    ),
  secondName: string().matches(
    /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/,
    'Información Inválida. Ingresa solo letras por favor.'
  ),
  firstLastName: string()
    .required('Campo requerido')
    .matches(
      /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/,
      'Información Inválida. Ingresa solo letras por favor.'
    ),
  secondLastName: string().matches(
    /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/,
    'Información Inválida. Ingresa solo letras por favor.'
  ),
  birthDate: Yup.date()
    .nullable()
    .max(new Date(Date.now() - 567648000000), 'Debes ser mayor de 18 años.')
    .required('Este campo es requerido'),
  nationality: object({
    isoCode: string().required(),
    countryName: string().required(),
  }),
  identificationValue: string().test('rutValidation', function (value) {
    const rut = rutHelpers.rutClean(value || '');
    if (!rutValidate(rut)) {
      const { path, createError } = this;
      return createError({ path, message: 'Ingresar un rut correcto' });
    } else {
      return true;
    }
  }),
  reason: array().min(1).required('Debe escoger una razón para utilizar CurrencyBird'),
});

export const PersonalInformation2Schema = object({
  maritalStatus: string().nullable(),
  profession: string().required('Campo requerido'),
  degree: string().when(['profession'], {
    is: (profession: string) => profession === 'Estudiante' || profession === 'Independiente',
    then: string().required('Campo requerido'),
  }),
  nationality: object({
    isoCode: string().required(),
    countryName: string().required(),
  }),
  economicActivityCode: object().when(['profession'], {
    is: (profession: string) => profession === 'Independiente' || profession === 'Empleado',
    then: object().shape({
      category: string().required(),
      description: string().required(),
    }),
  }),
  employerName: string().when(['profession'], {
    is: (profession: string) => profession === 'Empleado',
    then: string().max(45, 'No debe exceder 45 caracteres').required('Campo requerido'),
  }),
  employerPosition: string().when(['profession'], {
    is: (profession: string) => profession === 'Empleado',
    then: string().max(45, 'No debe exceder 45 caracteres').required('Campo requerido'),
  }),
  gender: string().required('Campo requerido'),
  pep: string().required('Campo requerido'),
  // art150: Yup.boolean().when(['maritalStatus'], {
  //   is: 'sociedad',
  //   then: Yup.boolean().oneOf([true], 'Debes firmar y aceptar la declaración de Articulo 150'),
  // }),
});

export const ContactInformationSchema = object({
  phone: string()
    .required('Campo requerido')
    .matches(/^[+0-9]{9,25}$/, 'Número de telefono no corresponde'),
  address: string()
    .required('Campo requerido')
    .matches(
      /([A-ZÑa-zñ]+\s?\d+)|(\d+\s?[A-ZÑa-zñ]+)|([A-ZÑa-zñ]+$)/,
      'Tu dirección parece incompleta. Ingresar solo letras y números.'
    ),
  country: object({
    isoCode: string().required(),
    countryName: string().required(),
  }),
  city: string().when('country', {
    is: (country: ICountryData) => country.isoCode !== 'CL',
    then: string().required('Campo requerido'),
  }),
  billAddress: string().when('country', {
    is: (country: ICountryData) => country.isoCode !== 'CL',
    then: string().nullable().required('Campo requerido'),
  }),
  postalCode: string().test('postalCode', 'Código postal no valido', (value, ctx) => {
    if (!value || !postcodeValidator(value, ctx.parent.country.isoCode)) {
      return false;
    }
    return true;
  }),
  commune: object({
    id: string().required(),
    value: string().required(),
  }),
  chileanPostalCode: string().test(
    'postalCode',
    'Código postal de facturación no valido ',
    (value, ctx) => {
      if (ctx.parent.country.isoCode != 'CL' && (!value || !postcodeValidator(value, 'CL'))) {
        return false;
      }
      return true;
    }
  ),
});

export const DocumentSchema = object({
  documentFront: mixed().required('Campo requerido'),
  idExpirationDate: Yup.date().nullable().required('Este campo es requerido'),
});
export const Document2Schema = object({
  documentBack: mixed().required('Campo requerido'),
});
