import React, { useEffect, useRef } from 'react';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import FormikSelect from '../../../FormikFields/FormikSelect';
import { IPersonalInformation2 } from '../personFormTypes';
import { useAuth } from '../../../../hooks/useAuth';
import APIProspect from '../../../../services/APIProspect.service';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import InfoIcon from '@material-ui/icons/Info';
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextFieldMaterial from '@material-ui/core/TextField';
import Popper from '@material-ui/core/Popper';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Art150 from '../../../TermsAndConditions/Art150';
import DialogPep from './dialogPep';
import useStyles from './PersonalInformation.styles';

const muiTheme = createTheme({
  overrides: {
    MuiListSubheader: {
      root: {
        backgroundColor: `#fff !important`,
        fontSize: 18,
        lineHeight: `25px`,
        fontFamily: `Montserrat !important`,
        color: `#272727e8`,
        fontWeight: 600,
      },
      sticky: {
        position: `static`,
      },
    },
  },
});

export interface IKeyValue {
  [key: string]: string;
}

const genders: IKeyValue = {
  female: 'Femenino',
  male: 'Masculino',
};

const professionData: IKeyValue = {
  Estudiante: 'Estudiante',
  Independiente: 'Independiente',
  Empleado: 'Empleado(a)',
  Jubilado: 'Jubilado(a) o Pensionado(a)',
  Housewife: 'Dueño(a) de casa',
};

const PersonalInformation2 = (props: IPersonalInformation2) => {
  const classes = useStyles();
  const { getBearer } = useAuth();
  const {
    profession,
    degree,
    maritalStatus,
    employerName,
    employerPosition,
    economicActivityCode,
    maritalStatusSection,
    art150,
    genderSection,
    gender,
  } = props;
  const formikContext = useFormikContext<{
    profession: string;
    economicActivityCode: string;
    maritalStatus: string;
    pep: boolean;
    art150: boolean;
    externalReport: {};
    nationality: ICountryData;
    nationalityType: string;
  }>();
  const [siiCodeGroup, setSiiCodeGroup] = React.useState<ISiiCode[] | null>([]);
  const isEquifaxReportEmpty = Object.keys(formikContext.values.externalReport).length === 0;

  const isFirstRun = useRef(true);
  const [open, setOpen] = React.useState(false);

  const { setFieldValue } = useFormikContext();
  const [nationalities, setNationalities] = React.useState([]);
  const PopperMy = function (props: any) {
    return (
      <MuiThemeProvider theme={muiTheme}>
        {' '}
        <Popper {...props} className={classes.poppers} placement="bottom-start" />{' '}
      </MuiThemeProvider>
    );
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let currentRoute = window.location.pathname;
      window.addEventListener('popstate', (event) => {
        currentRoute = window.location.pathname;
      });
      const enviarRutaAGTM = () => {
        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'gatsby-route-change',
            ruta: currentRoute,
          });
        }
      };

      if (currentRoute !== '/signup-form/personal-information') {
        history.pushState({}, '', '/signup-form/personal-information');
        enviarRutaAGTM();
      }
    }
  }, []);

  // const maritalStatusData: IKeyValue = isEquifaxReportEmpty
  // ? {
  //     single: 'Soltero(a)',
  //     widower: 'Viudo(a)',
  //     divorced: 'Divorciado(a)',
  //     sociedad: 'Casado(a): Sociedad Conyugal',
  //     bienes: 'Casado(a): Separación total de bienes',
  //     part: 'Casado(a): Participación en los Gananciales',
  //     cohabitant: 'Conviviente Civil'
  //   }
  // : {
  //     sociedad: 'Casado(a): Sociedad Conyugal',
  //     bienes: 'Casado(a): Separación total de bienes',
  //     part: 'Casado(a): Participación en los Gananciales'
  // };

  const handleClickOpen = () => {
    if (open == false) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (formikContext.values.maritalStatus != 'sociedad') {
      setFieldValue('art150', false);
    }
  }, [formikContext.values.maritalStatus]);

  useEffect(() => {
    APIProspect.get('signup/sii/categories').then((response) => {
      setSiiCodeGroup(response.data);
    });
  }, []);

  useEffect(() => {
    APIProspect.defaults.headers.common['Authorization'] = getBearer();
    APIProspect.get('signup/nationalities').then((response) => {
      setNationalities(response.data);
    });
  }, []);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    } else {
      setFieldValue('degree', '');
      setFieldValue('employerName', '');
      setFieldValue('employerPosition', '');
      setFieldValue('economicActivityCode', {});
    }
  }, [formikContext.values.profession]);

  return (
    <Container maxWidth="sm">
      <DialogPep open={open} />
      <Grid className={classes.formProperties} container spacing={3}>
        <h4 className={classes.title}>Completa tu información personal</h4>
        <p className={classes.subtitle}> Paso 2/2 </p>
        {/* {maritalStatusSection && (
          <Grid item xs={12} sm={12}>
            <FormikSelect
              elements={maritalStatusData}
              label={maritalStatus.label}
              name={maritalStatus.name}
            />
          </Grid>
        )} */}
        {(formikContext.values.nationalityType === 'E' ||
          formikContext.values.nationalityType === 'N') && (
          <Grid item xs={12}>
            <Autocomplete
              options={nationalities}
              disableClearable
              value={formikContext.values.nationality}
              getOptionLabel={(option: ICountryData) =>
                option.countryName ? option.countryName : ''
              }
              getOptionSelected={(option: ICountryData, value: ICountryData) =>
                value.isoCode === option.isoCode
              }
              onChange={(event: React.ChangeEvent<Record<string, unknown>>, value: ICountryData) =>
                setFieldValue('nationality', value)
              }
              renderInput={(params) => (
                <TextFieldMaterial
                  {...params}
                  helperText={formikContext.errors.nationality ? 'Seleccionar nacionalidad' : null}
                  error={
                    formikContext.touched.nationality && Boolean(formikContext.errors.nationality)
                  }
                  variant="outlined"
                  label="Nacionalidad"
                  placeholder="Nacionalidad"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
        )}
        {genderSection && (
          <Grid item xs={12} sm={12}>
            <FormikSelect elements={genders} label={gender.label} name={gender.name} />
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <FormikSelect elements={professionData} label={profession.label} name={profession.name} />
        </Grid>
        {formikContext.values.profession === 'Estudiante' ? (
          <>
            <Grid item xs={12}>
              <Field
                component={TextField}
                fullWidth
                label="Carrera"
                name={degree.name}
                variant="outlined"
                placeholder="Carrera"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </>
        ) : null}
        {formikContext.values.profession === 'Independiente' ? (
          <>
            <Grid item xs={12}>
              <Field
                component={TextField}
                fullWidth
                label="Oficio o Profesión"
                name={degree.name}
                variant="outlined"
                placeholder="Oficio o profesión"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <p className={classes.economicActivitySubtitle}>Actividad económica</p>
            <Grid item xs={12}>
              {siiCodeGroup && (
                <Autocomplete
                  options={siiCodeGroup}
                  PopperComponent={PopperMy}
                  disableClearable
                  value={formikContext.values.economicActivityCode}
                  groupBy={(option) => option.category}
                  getOptionLabel={(option: any) => (option.description ? option.description : '')}
                  getOptionSelected={(option: any, value: any) => value.value === option.value}
                  onChange={(event: inputEventType, value) =>
                    setFieldValue('economicActivityCode', value)
                  }
                  renderInput={(params) => (
                    <TextFieldMaterial
                      {...params}
                      helperText={
                        formikContext.errors.economicActivityCode
                          ? 'Seleccionar una actividad económica'
                          : null
                      }
                      error={
                        formikContext.touched.economicActivityCode &&
                        Boolean(formikContext.errors.economicActivityCode)
                      }
                      variant="outlined"
                      label="Actividad económica"
                    />
                  )}
                />
              )}
            </Grid>
          </>
        ) : null}
        {formikContext.values.profession === 'Empleado' ? (
          <>
            <Grid item xs={12}>
              <Field
                component={TextField}
                fullWidth
                label={employerName.label}
                name={employerName.name}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              {siiCodeGroup && (
                <Autocomplete
                  options={siiCodeGroup}
                  PopperComponent={PopperMy}
                  disableClearable
                  value={formikContext.values.economicActivityCode}
                  groupBy={(option) => option.category}
                  getOptionLabel={(option: any) => (option.description ? option.description : '')}
                  // getOptionSelected={(option:any, value:any) => value.value === option.value}
                  onChange={(event: inputEventType, value) =>
                    setFieldValue('economicActivityCode', value)
                  }
                  renderInput={(params) => (
                    <TextFieldMaterial
                      {...params}
                      helperText={
                        formikContext.errors.economicActivityCode
                          ? 'Seleccionar una actividad económica'
                          : null
                      }
                      error={
                        formikContext.touched.economicActivityCode &&
                        Boolean(formikContext.errors.economicActivityCode)
                      }
                      variant="outlined"
                      label="Actividad económica"
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Field
                component={TextField}
                fullWidth
                label={employerPosition.label}
                name={employerPosition.name}
                variant="outlined"
              />
            </Grid>
          </>
        ) : null}

        <Grid item xs={12}>
          <FormikSelect elements={{ true: 'Sí', false: 'No' }} label="¿Eres PEP?" name="pep" />
          <Link onClick={handleClickOpen} className={classes.helperText}>
            ¿Qué es ser PEP?
            <InfoIcon className={classes.infoIcon} />
          </Link>
        </Grid>

        {/* {formikContext.values.maritalStatus === 'sociedad' && maritalStatusSection && (
          <>
          <Grid item xs={12}>
            <Art150 />
            <Field
              type="checkbox"
              name="art150"
              value={formikContext.values.art150}
              checked={formikContext.values.art150}
              onChange={formikContext.handleChange}
            />
          He leído y acepto declaración de Articulo 150
        </Grid>
        <Grid item xs={12} style={{paddingTop:'0px', paddingLeft:'15px'}}>
        <FormHelperText className={classes.helperText} style={{ marginTop: '0px', color: 'red' }}>
          {formikContext.values.art150 === false
            ? "Debes firmar la declaración de articulo 150"
            : formikContext.errors.art150
              ? formikContext.errors.art150
              : null}
        </FormHelperText>
        </Grid>
        </>
        )} */}
      </Grid>
    </Container>
  );
};

export default PersonalInformation2;
