import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    termsContainer: {
      [theme.breakpoints.down(501)]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
    title: {
      width: `100%`,
      color: `#074F71`,
      textAlign: `center`,
      margin: 0,
      fontSize: 18,
      marginTop: 15,
      [theme.breakpoints.down(501)]: {
        fontSize: 14,
      },
      // lineHeight:`52px`,
    },
    subtitle: {
      width: `100%`,
      color: `#074F71`,
      textAlign: `center`,
      marginTop: 0,
      fontSize: 14,
      [theme.breakpoints.down(501)]: {
        fontSize: 11,
      },
    },
    formProperties: {
      '& label': {
        [theme.breakpoints.down(501)]: {
          fontSize: 13,
        },
        color: `#074f71`,
      },
      '& fieldset': {
        borderColor: `#074f71`,
      },
      [theme.breakpoints.down(501)]: {
        fontSize: 12,
        '& input': {
          fontSize: 13,
        },
      },
    },
    paper: {
      backgroundColor: `#074F710D`,
      height: 374,
      paddingLeft: 41,
      paddingRight: 41,
      paddingTop: 15,
      [theme.breakpoints.down(501)]: {
        paddingLeft: 15,
        paddingRight: 15,
      },
    },
    titlePaper: {
      color: `#074F71`,
      marginLeft: 27,
      fontSize: 18,
      fontWeight: 500,
      marginTop: 0,
      [theme.breakpoints.down(501)]: {
        fontSize: 12,
        marginLeft: 5,
      },
    },
    textArea: {
      width: `100%`,
      maxHeight: `279px`,
      height: `279px !important`,
      fontFamily: `Montserrat`,
      borderStyle: `none`,
      padding: '5px 27px 24px 27px',
      color: `#004F71`,
      overflow: `scroll !important`,
      backgroundColor: `#fff`,
      resize: `none`,
      [theme.breakpoints.down(501)]: {
        padding: '5px',
      },
      '& section': {
        paddingTop: `0px !important`,
        marginTop: `0px !important`,
        paddingBottom: `0px !important`,
        '& #container': {
          padding: `0px !important`,
          '& h1': {
            display: `none`,
          },
          '& h2': {
            fontSize: 18,
            [theme.breakpoints.down(501)]: {
              fontSize: 14,
            },
          },
          '& p': {
            fontSize: 12,
            lineHeight: `14px`,
          },
        },
      },
    },
    formTerms: {
      marginLeft: 15,
      [theme.breakpoints.down(501)]: {
        marginLeft: `-10px`,
      },
      '& svg': {
        width: 18,
        height: 18,
      },
      '& span': {
        fontSize: 14,
        [theme.breakpoints.down(501)]: {
          fontSize: 10,
          lineHeight: `13px`,
        },
      },
    },
  })
);
export default useStyles;
