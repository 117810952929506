import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import useStyles from './PersonTermsAndConditions.styles';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Terms from '../../../TermsAndConditions/Terms';

export interface IKeyValue {
  [key: string]: string;
}

const TermsAndCondition = (props: ITerms) => {
  const classes = useStyles();
  const formikContext =
    useFormikContext<{ terms: boolean; gender: string; maritalStatus: string; art150: boolean }>();

  const { setFieldValue } = useFormikContext();
  const [state, setState] = React.useState({
    terms: false,
    electronicDocuments: false,
    patrimony: formikContext.values.art150,
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let currentRoute = window.location.pathname;
      window.addEventListener('popstate', (event) => {
        currentRoute = window.location.pathname;
      });
      const enviarRutaAGTM = () => {
        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'gatsby-route-change',
            ruta: currentRoute,
          });
        }
      };
      if (currentRoute !== '/signup-form/terms') {
        history.pushState({}, '', '/signup-form/terms');
        enviarRutaAGTM();
      }
    }
  }, []);

  useEffect(() => {
    if (state.electronicDocuments == true && state.terms == true) {
      setFieldValue('terms', true);
    } else {
      setFieldValue('terms', false);
    }
  }, [state]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <Container className={classes.termsContainer}>
      <Grid className={classes.formProperties} container spacing={3}>
        <h4 className={classes.title}>Aceptar términos y condiciones</h4>
        <p className={classes.subtitle}>
          {' '}
          Para poder finalizar tu proceso de registro, por favor leer y aceptar los términos
          asociados.
        </p>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <p className={classes.titlePaper}> 1. Términos y condiciones </p>
            <div className={classes.textArea}>
              <Terms />
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.terms}
                  onChange={handleChange}
                  name="terms"
                  color="primary"
                />
              }
              className={classes.formTerms}
              label="He leído y acepto los términos y condiciones"
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.electronicDocuments}
                onChange={handleChange}
                name="electronicDocuments"
                color="primary"
              />
            }
            className={classes.formTerms}
            label="Declaro haber leído el/los documento(s) y que por medio de mi aceptación, manifiesto mi intención de firmar los documentos en forma electrónica."
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default TermsAndCondition;
