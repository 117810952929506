import React from 'react';
import { Field, ErrorMessage } from 'formik';
import { Select } from 'formik-material-ui';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { FormikConfig, FormikValues } from 'formik';
import FormHelperText from '@material-ui/core/FormHelperText';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextError from './TextError';
export interface ISelect extends Pick<FormikConfig<FormikValues>, 'validationSchema'> {
  elements: { [key: string]: string };
  label: string;
  name: string;
  disabled?: boolean;
  helperText?: string;
}
const FormikSelect = (props: ISelect) => {
  const { elements, label, name, validationSchema, disabled, helperText } = props;
  return (
    <FormControl fullWidth>
      <InputLabel variant="outlined" htmlFor={label}>
        {label}
      </InputLabel>

      <Field
        component={Select}
        label={label}
        id={name}
        name={name}
        variant="outlined"
        fullWidth
        disabled={disabled}
        inputProps={{
          id: name,
        }}
      >
        {Object.keys(elements).map((key) => (
          <MenuItem key={key} value={key}>
            {elements[key]}
          </MenuItem>
        ))}
      </Field>
      <ErrorMessage name={name} className={name} component={TextError} />
      {helperText != undefined ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  );
};

export default FormikSelect;
