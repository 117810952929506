import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      width: '100%',
      color: '#074F71',
      textAlign: 'center',
      margin: 0,
      fontSize: 18,
      marginTop: 35,
    },
    subtitle: {
      width: '100%',
      color: '#074F71',
      textAlign: 'center',
      marginTop: 10,
      fontSize: 14,
    },
    foreignMessages: {
      width: '100%',
      color: '#074F71',
      marginTop: 10,
      fontSize: 14,
    },
    card: {
      background: '#074F710D',
    },
    divider: {
      color: '#074F71',
    },
    cardContent: {
      padding: '0 80px 80px 80px',
      [theme.breakpoints.down(501)]: {
        padding: '0 20px 20px 20px',
      },
    },
    cardtitle: {
      width: '100%',
      fontWeight: 600,
      color: '#074F71',
      textAlign: 'center',
      fontSize: 18,
    },
    question: {
      width: '100%',
      color: '#074F71',
      textAlign: 'end',
      margin: 0,
      fontSize: 18,
      marginTop: 35,
    },
    questionBack: {
      width: '100%',
      color: '#074F71',
      textAlign: 'end',
      margin: 0,
      fontSize: 18,
      marginTop: 8,
      marginBottom: 10,
    },
    svgBack: {
      marginTop: '8px',
      color: '#074F71',
      marginBottom: '10px',
    },
    svg: {
      marginTop: '35px',
      color: '#074F71',
    },
    formProperties: {
      '& label': {
        color: '#074f71',
      },
      '& fieldset': {
        borderColor: '#074f71',
      },
    },
    dniInfo: {
      color: '#074F71',
      fontSize: 10,
    },
    dniReference: {
      color: '#074F71',
      textAlign: 'center',
      fontSize: 10,
    },
    dniContainer: {
      marginTop: 10,
    },
    explanation: {
      color: 'grey',
      fontStyle: 'italic',
      paddingTop: 0,
      textAlign: 'center',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalLink: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    modalContent: {
      backgroundColor: 'white', // Fondo blanco
      padding: theme.spacing(4), // Espaciado interno
      borderRadius: '8px', // Bordes redondeados
      maxWidth: '400px', // Ancho máximo del contenido
      textAlign: 'center', // Alineación centrada
    },
    modalTrigger: {
      cursor: 'pointer',
    },
  })
);
export default useStyles;
