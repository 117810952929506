import React from 'react';
import { Button, CircularProgress, Grid, Container } from '@material-ui/core';
import { IStepperButtons } from './formTypes';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonLeft: {
      color: `#074F71B3`,
      textTransform: `capitalize`,
      float: `left`,
      fontSize: 18,
      [theme.breakpoints.down(501)]: {
        fontSize: 13,
      },
    },
    buttonRight: {
      color: `#F8B133`,
      fontWeight: `bold`,
      textTransform: `capitalize`,
      float: `right`,
      fontSize: 18,
      [theme.breakpoints.down(501)]: {
        fontSize: 13,
      },
    },
    item: {
      width: `50%`,
    },
    buttonSection: {
      paddingTop: 30,
      paddingBottom: 30,
    },
  })
);

const WebStepperButtons = (props: IStepperButtons) => {
  const classes = useStyles();
  const { isSubmitting, isLastStep, isTerms, step, handleBack, terms, handleNext } = props;
  const handleSubmitting = () => {
    if (isSubmitting && isLastStep) {
      return 'Registrandose';
    } else if (isSubmitting) {
      return 'Enviando';
    } else if (isLastStep) {
      return 'Terminar registro';
    } else if (isTerms) {
      return 'Aceptar';
    } else {
      return 'Siguiente';
    }
  };
  return (
    <Container maxWidth="sm">
      <Grid container justifyContent="center" className={classes.buttonSection} spacing={0}>
        {step > 1 && step != 3 ? (
          <Grid item className={classes.item}>
            <Button
              disabled={isSubmitting}
              className={classes.buttonLeft}
              color="primary"
              startIcon={<NavigateBeforeIcon size="1rem" />}
              onClick={() => handleBack()}
            >
              Anterior
            </Button>
          </Grid>
        ) : (
          <Grid item className={classes.item}>
            {/* <Button className={classes.buttonLeft}>
          </Button> */}
          </Grid>
        )}
        <Grid item className={classes.item}>
          <Button
            className={classes.buttonRight}
            startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
            endIcon={<NavigateNextIcon size="1rem" />}
            disabled={isSubmitting || (isLastStep && !terms)}
            color="primary"
            type="submit"
          >
            {handleSubmitting()}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default WebStepperButtons;
