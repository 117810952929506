import {
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
} from '@material-ui/core';
import { Form, Formik, FormikConfig, FormikValues, FormikHelpers } from 'formik';
import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
  MuiThemeProvider,
  createTheme,
} from '@material-ui/core/styles';
import { navigate } from 'gatsby';
import WebStepperButtons from './WebStepperButtons';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import StepConnector from '@material-ui/core/StepConnector';
import TransitionAlert from '../../Common/Alert';
import * as Sentry from '@sentry/gatsby';
import { useAuth } from '../../../hooks/useAuth';

const defaultTheme = createTheme();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    scrollText: {
      flexGrow: 1,
      overflow: 'auto',
      height: 300,
    },
    stepper: {
      padding: `20px 0px 5px`,
      [theme.breakpoints.down(501)]: {
        padding: `20px 0px 20px`,
      },
    },
  })
);

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#fab234',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#fab234',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const muiTheme = createTheme({
  overrides: {
    MuiStepIcon: {
      root: {
        color: '#074F714D', // or 'rgba(0, 0, 0, 1)'
        '&$active': {
          color: '#F8B133',
        },
        '&$completed': {
          color: '#fab234',
        },
      },
    },
    MuiStepLabel: {
      label: {
        color: `#074F714D`,
        fontFamily: `Montserrat`,
        fontSize: 14,
        '&$active': {
          color: '#074F71',
          fontWeight: 'bold',
        },
        '&$completed': {
          color: '#074F71',
        },
        [defaultTheme.breakpoints.down(501)]: {
          display: `none`,
        },
      },
    },
    MuiStepConnector: {
      root: {
        '&$horizontal': {
          left: `calc(-45% + 20px) !important`,
          right: `calc(55% + 20px) !important`,
        },
      },
      line: {
        '&$lineHorizontal': {
          borderColor: `#074f714d`,
        },
      },
    },
  },
});

export interface IFormikStep
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema' | 'onSubmit'> {
  label: string;
  setPreviousGlobalStep: boolean;
  setNextGlobalStep: boolean;
  error?: boolean;
  fetching?: boolean;
  next?: boolean;
  stepName?: string;
}

export const FormikStep = ({ children, ...props }: IFormikStep) => {
  return <React.Fragment>{children}</React.Fragment>;
};

export const FormikStepper = ({ children, ...props }: FormikConfig<FormikValues>) => {
  const childrenArray = React.Children.toArray(children) as React.ReactElement<IFormikStep>[];
  const [step, setStep] = useState(0);
  const [globalStep, setGlobalStep] = useState(0);
  const currentChild = childrenArray[step];
  const [completed, setCompleted] = useState(false);
  const currentProgress = 100 * (step / (childrenArray.length - 1));
  const isLastStep = step === childrenArray.length - 1;
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertDescription, setAlertDescription] = useState('');
  const [alertSeverity, setAlertSeverity] = useState<'success' | 'info' | 'warning' | 'error'>(
    'error'
  );
  const [loadingOpen, setLoadingOpen] = useState(false);

  const { user, getBearer } = useAuth();
  const email = user ? user : undefined;

  const [openAlertDialog, setOpenAlertDialog] = useState(true);
  const handleOpenAlertDialog = () => setOpenAlertDialog(true);
  const handleCloseAlertDialog = () => setOpenAlertDialog(false);

  Sentry.init({
    dsn: process.env.GATSBY_DSN,
    environment: process.env.GATSBY_SENTRY_ENV,
    enabled: (() =>
      ['production', 'test'].indexOf(process.env.GATSBY_SENTRY_ENV || 'test') !== -1)(),
    debug: true,
    initialScope: {
      user: { email: email, token: getBearer() },
    },
  });

  useEffect(() => {
    if (currentChild.props.next === false) {
      if (currentChild.props.setNextGlobalStep) {
        setGlobalStep((s) => s + 1);
      }
      setStep((s) => s + 1);
    }
  }, [step, currentChild.props.next]);

  const handleSubmit = async (values: FormikValues, helpers: FormikHelpers<FormikValues>) => {
    let response;
    if (isLastStep) {
      setLoadingOpen(true);
      try {
        if (currentChild.props.stepName == 'info-contact') {
          response = await currentChild.props.onSubmit(values, helpers);
          if (response == false) {
            return false;
          }
        }
        setCompleted(true);
        values.terms = true;
        const saveClient = await props.onSubmit(values, helpers);
      } catch (e: any) {
        setLoadingOpen(false);
        Sentry.captureException(e);
        setAlertTitle('Hubo un error al completar el perfil');

        setAlertOpen(true);
        setAlertSeverity('error');
        if (e.response.data.message == 'INVALID_POSTAL_CODE') {
          setAlertDescription('Código postal es requerido');
        }
        if (e.response.data.message == 'INVALID_CHILEAN_POSTAL_CODE') {
          setAlertDescription('Código postal de facturación es requerido');
        }
        if (
          e.response.data.message == 'INVALID_REGION' ||
          e.response.data.message == 'INVALID_COMMUNE' ||
          e.response.data.message == 'INVALID_CITY'
        ) {
          setAlertDescription('Debes seleccionar una dirección valida.');
        }
      }
    } else {
      try {
        response = await currentChild.props.onSubmit(values, helpers);
        if (response == false) {
          return false;
        }

        if (response?.data?.result === 'success') {
          setAlertTitle(response.data.alertTitle);
          setAlertOpen(true);
          setAlertSeverity('success');
          setAlertDescription(response.data.message);
        }
        if (
          response?.data?.result === 'chile-old-id' ||
          response?.data?.result === 'attempts-limit-reached'
        ) {
          setAlertTitle(response.data.alertTitle);
          setAlertOpen(true);
          setAlertSeverity('warning');
          setAlertDescription(response.data.message);
        }

        if (currentChild.props.setNextGlobalStep) {
          setGlobalStep((s) => s + 1);
        }
        setStep((s) => s + 1);
        window.scrollTo(0, 0);
      } catch (e: any) {
        Sentry.captureException(e);
        if (e.response.data.data) {
          setAlertDescription(e.response.data.data.message);
        } else if (e.response.data.message) {
          setAlertDescription(e.response.data.message);
        } else {
          setAlertDescription(
            'Por favor, intenta nuevamente. Si el error persiste solicita ayuda a través del chat.'
          );
        }
        setAlertSeverity('error');
        setAlertOpen(true);
        setAlertTitle('Hemos detectado un error');
      }
    }
  };

  const handleNextIfCompleted = () => {
    if (currentChild.props.next) {
      if (currentChild.props.setNextGlobalStep) {
        setGlobalStep((s) => s + 1);
      }
      setStep((s) => s + 1);
    }
  };

  const handleBackClick = () => {
    if (currentChild.props.setPreviousGlobalStep) {
      setGlobalStep((s) => s - 1);
    }
    setStep((s) => s - 1);
  };
  const classes = useStyles();
  return (
    <>
      <Formik
        {...props}
        validationSchema={currentChild.props.validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values }) => (
          <Form autoComplete="off">
            <Container maxWidth="md">
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                style={{ padding: '20px 0px 0px' }}
              >
                <Grid item xs={12}></Grid>
                <Grid item xs={12}>
                  {currentChild.props.label === 'Documentación' ? (
                    <Dialog
                      open={alertOpen}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      maxWidth="md"
                    >
                      <DialogContent>
                        <TransitionAlert
                          closeIcon={false}
                          setAlertOpen={setAlertOpen}
                          open={alertOpen}
                          title={alertTitle}
                          description={alertDescription}
                          severity={alertSeverity}
                        />
                      </DialogContent>
                      <DialogActions style={{ display: 'flex' }}>
                        <Button
                          variant="outlined"
                          style={{ margin: '0 auto' }}
                          color="primary"
                          onClick={() => setAlertOpen(false)}
                        >
                          Entendido
                        </Button>
                      </DialogActions>
                    </Dialog>
                  ) : (
                    alertOpen && (
                      <TransitionAlert
                        setAlertOpen={setAlertOpen}
                        open={alertOpen}
                        title={alertTitle}
                        description={alertDescription}
                        severity={alertSeverity}
                      />
                    )
                  )}
                </Grid>
                <MuiThemeProvider theme={muiTheme}>
                  <Stepper
                    className={classes.stepper}
                    alternativeLabel
                    activeStep={globalStep}
                    connector={<QontoConnector />}
                  >
                    {childrenArray
                      .filter((child, index) => child.props.setPreviousGlobalStep || index === 0)
                      .map((child, index) => (
                        <Step key={child.props.label} completed={globalStep > index || completed}>
                          <StepLabel>{child.props.label}</StepLabel>
                        </Step>
                      ))}
                  </Stepper>
                </MuiThemeProvider>
              </Grid>
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                style={loadingOpen ? { opacity: 0.2, zIndex: 300 } : { opacity: 1 }}
                // style={{ minHeight: '400px'}}
              >
                {currentChild.props.fetching && (
                  <div
                    style={{
                      display: 'flex',
                      height: '550px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CircularProgress size="6rem" />
                  </div>
                )}
                {!currentChild.props.fetching && currentChild}
                <Backdrop
                  open={isSubmitting || loadingOpen}
                  // open={true}
                  style={{ zIndex: 1000, color: '#074F71' }}
                >
                  <div style={{ display: 'block' }}>
                    <div style={{ display: 'flex' }}>
                      <CircularProgress style={{ margin: '0 auto' }} size="6rem" color="inherit" />
                    </div>
                    {currentChild.props.label === 'Documentación' && (
                      <Typography
                        style={{ color: '#fff', marginTop: '32px' }}
                        component="p"
                        variant="h6"
                        align="center"
                      >
                        Estamos validando tu cédula de identidad... esto puede tardar unos segundos.
                      </Typography>
                    )}
                  </div>
                </Backdrop>
              </Grid>
              <WebStepperButtons
                isSubmitting={isSubmitting}
                terms={values.terms}
                isLastStep={isLastStep}
                // isTerms={currentChild.props.terms}
                step={step}
                handleBack={handleBackClick}
                handleNext={handleNextIfCompleted} // Agrega esta función al botón "Siguiente"
              />
            </Container>
          </Form>
        )}
      </Formik>
    </>
  );
};
