import axios from 'axios';
import { initializeAxiosMockAdapter, isMockEnabled } from '../mock/mock.config';

const APIGoogle = axios.create({
  baseURL: process.env.GATSBY_GOOGLE_API_GATEWAY_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

if (isMockEnabled()) {
  initializeAxiosMockAdapter(APIGoogle);
}

export default APIGoogle;
