import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Field, useFormikContext, ErrorMessage } from 'formik';
import { TextField } from 'formik-material-ui';
import TextFieldMaterial from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import _ from 'lodash';
import APIProspect from '../../../../services/APIProspect.service';
import APIGoogle from '../../../../services/ApiGoogle.service';
import { IContactInformation } from '../personFormTypes';
import useStyles from './ContactInformation.styles';
import Checkbox from '@material-ui/core/Checkbox';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import es from 'react-phone-input-2/lang/es.json';
import FormHelperText from '@material-ui/core/FormHelperText';

export interface IKeyValue {
  [key: string]: string;
}

const ContactInformation = (props: IContactInformation) => {
  const classes = useStyles();
  const { phone, city, postalCode, chileanPostalCode } = props;
  const addressRegex = /([A-ZÑa-zñ]+\s?\d+)|(\d+\s?[A-ZÑa-zñ]+)|([A-ZÑa-zñ]+$)/;
  const [loading, setLoading] = useState(false);
  const [selectedGoogleOption, setSelectedGoogleOption] = useState(false);
  const [selectedGoogleOptionBillAddress, setSelectedGoogleOptionBillAddress] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isCheckboxCheckedBillAddress, setIsCheckboxCheckedBillAddress] = useState(false);
  const formikContext = useFormikContext<{
    country: { id: string; isoCode: string; countryName: string };
    commune: string;
    region: string;
    address: string;
    city: string;
    postalCode: string;
    chileanPostalCode: string;
    addressValidation: boolean;
    billAddressValidation: boolean;
    billAddress: string;
    isValidAddress: boolean;
    streetNumber: string;
    phone: string;
  }>();
  const [countries, setCountries] = React.useState('');
  const isFirstRun = useRef(true);
  const [addressOptions, setAddressOptions] = React.useState<string[]>([]);
  const [billAddressOptions, setBillAddressOptions] = React.useState<string[]>([]);
  const [initialAddress] = React.useState<string>('');
  const [initialBillAddress] = React.useState<string>('');
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let currentRoute = window.location.pathname;
      window.addEventListener('popstate', (event) => {
        currentRoute = window.location.pathname;
      });
      const enviarRutaAGTM = () => {
        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'gatsby-route-change',
            ruta: currentRoute,
          });
        }
      };
      if (currentRoute !== '/signup-form/contact-information') {
        history.pushState({}, '', '/signup-form/contact-information');
        enviarRutaAGTM();
      }
    }
  }, []);

  useEffect(() => {
    formikContext.setFieldValue('address', '');
    formikContext.setFieldValue('billAddress', '');
  }, []);

  const [missingData, setMissingData] = useState({
    city: '',
    commune: '',
    isValidAddress: '',
    places: [],
    postalCode: '',
    region: '',
    streetNumber: '',
  });

  const delayedGoogleApiRequest = useCallback(
    _.debounce(
      (address: string, country: string, type: string) =>
        autocompleteRequest(address, country, type),
      1200
    ),
    []
  );

  const isPostalCodeVisible =
    missingData.postalCode === null &&
    missingData.postalCode !== '' &&
    formikContext.values.country?.isoCode === 'CL' &&
    formikContext.values.isValidAddress == true;

  const isChileanPostalCodeVisible =
    missingData.postalCode === null &&
    missingData.postalCode !== '' &&
    formikContext.values.country?.isoCode != 'CL';
  formikContext.values.isValidAddress == true;

  useEffect(() => {
    formikContext.setFieldValue('isPostalCodeVisible', isPostalCodeVisible);
  }, [isPostalCodeVisible]);

  useEffect(() => {
    formikContext.setFieldValue('isChileanPostalCodeVisible', isChileanPostalCodeVisible);
  }, [isChileanPostalCodeVisible]);

  //Obtención de opciones para direcciones
  const autocompleteRequest = async (address: string, country: string, type: string) => {
    if (country === 'CL' && address.trim() !== '') {
      const ApiAddress = await APIProspect.get(
        `/signup/chile-address-components?address=${address}`
      ).then((response) => {
        setAddressOptions(response.data.predictions.map((p: any) => p.description));
      });
    } else {
      if (type === 'address' && address.trim() !== '') {
        await APIGoogle.post('', { value: address, country: country }).then((response) => {
          const options = response.data.body.predictions.map(
            (prediction: { description: string }) => prediction.description
          );
          setAddressOptions(options);
        });
      }
      if (type === 'billAddress' && address.trim() !== '') {
        await APIProspect.get(`/signup/chile-address-components?address=${address}`).then(
          (response) => {
            setBillAddressOptions(response.data.predictions.map((p: any) => p.description));
          }
        );
      }
    }
  };

  const containsNumber = (address: string) => {
    return /\d/.test(address);
  };

  const handleAddressChange = async (value: string) => {
    if (formikContext.values.country?.isoCode == 'CL') {
      formikContext.setValues({
        ...formikContext.values,
        region: '',
        commune: '',
        city: '',
        streetNumber: '',
      });
    }
    const isInitialAddress = value === initialAddress ? true : false;
    setFieldValue('address', value);
    if (!isInitialAddress) {
      const country: string = formikContext.values.country?.isoCode;
      if (!addressOptions.includes(value) && country) {
        delayedGoogleApiRequest(value, country, 'address');
      } else {
        containsNumber(formikContext.values.address)
          ? setFieldValue('addressValidation', true)
          : setFieldValue('addressValidation', false);
      }
    }
  };

  const handleBillAddressChange = async (value: string) => {
    if (formikContext.values.country?.isoCode != 'CL') {
      formikContext.setValues({
        ...formikContext.values,
        region: '',
        commune: '',
        streetNumber: '',
      });
    }
    const isInitialAddress = value === initialAddress ? true : false;
    setFieldValue('billAddress', value);
    if (!isInitialAddress) {
      const country: string = formikContext.values.country?.isoCode;
      if (!addressOptions.includes(value) && country) {
        delayedGoogleApiRequest(value, country, 'billAddress');
      } else {
        containsNumber(formikContext.values.billAddress)
          ? setFieldValue('addressValidation', true)
          : setFieldValue('addressValidation', false);
      }
    }
  };

  const handleAddressComponent = async (value: string) => {
    if (formikContext.values.country?.isoCode === 'CL' && value.trim() !== '') {
      setLoading(true);
      setLoadingMessage('Validando dirección');
      const { data } = await APIProspect.get(`/signup/chile-address-components?address=${value}`);
      setMissingData(data);
      setLoading(false);
      setLoadingMessage('');

      const normalizedValue = _.deburr(value);
      const matchingPrediction = data.predictions.find((prediction: any) => {
        const normalizedDescription = _.deburr(prediction.description);
        return normalizedDescription === normalizedValue;
      });

      const valuesToSet = matchingPrediction || data;

      formikContext.setValues({
        ...formikContext.values,
        region: valuesToSet.region,
        commune: valuesToSet.commune,
        city: valuesToSet.city,
        postalCode: valuesToSet.postalCode,
        isValidAddress: true,
        streetNumber: valuesToSet.streetNumber,
      });
    }
  };

  const handleBillAddressComponent = async (value: string) => {
    if (formikContext.values.country?.isoCode !== 'CL' && value.trim() !== '') {
      setLoading(true);
      setLoadingMessage('Validando dirección');
      const { data } = await APIProspect.get(`/signup/chile-address-components?address=${value}`);
      setMissingData(data);
      setLoading(false);
      setLoadingMessage('');

      const matchingPrediction = data.predictions.find(
        (prediction: any) => prediction.description === value
      );
      const valuesToSet = matchingPrediction || data;

      formikContext.setValues({
        ...formikContext.values,
        region: valuesToSet.region,
        commune: valuesToSet.commune,
        chileanPostalCode: valuesToSet.postalCode || '',
        isValidAddress: true,
        streetNumber: valuesToSet.streetNumber,
      });
    }
  };

  useEffect(() => {
    APIProspect.get('signup/nationalities').then((response) => {
      setCountries(response.data);
    });
  }, []);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    } else if (formikContext.values.country && formikContext.values.country?.isoCode === 'CL') {
      setFieldValue('address', '');
      setFieldValue('postalCode', '');
      setFieldValue('city', '');
      setFieldValue('billAddress', '');
      setFieldValue('chileanPostalCode', '');
    } else {
      setFieldValue('address', '');
      setFieldValue('city', '');
      setFieldValue('postalCode', '');
      setFieldValue('billAddress', '');
      setFieldValue('chileanPostalCode', '');
    }
  }, [formikContext.values.country]);

  return (
    <Container id="Contacto" maxWidth="sm">
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
        {loadingMessage && <p>{loadingMessage}</p>}
      </Backdrop>

      <Grid className={classes.formProperties} container spacing={3}>
        <h4 className={classes.title}>Información de contacto</h4>
        <p className={classes.subtitle}>
          Si tienes alguna duda, siempre puedes escribirnos al chat
        </p>
        <Grid item xs={12}>
          <PhoneInput
            country={'cl'}
            localization={es}
            component={TextField}
            enableSearch={true}
            label="Teléfono"
            name={phone.name}
            disableSearchIcon={true}
            autoFormat={false}
            placeholder={phone.label}
            onChange={(value, data, event, formattedValue) => {
              setFieldValue('phone', formattedValue);
            }}
            variant="outlined"
            inputStyle={{
              width: '100%',
              height: '55px',
              fontFamily: 'Montserrat',
              fontSize: '15px',
            }}
            searchPlaceholder="Buscar país"
            inputProps={{
              name: 'phone',
              required: true,
              autoFocus: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormHelperText className={classes.helperText}>
            {formikContext.touched.phone && formikContext.errors.phone
              ? 'Escríbe un teléfono de contacto'
              : null}
          </FormHelperText>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Autocomplete
            options={countries}
            value={formikContext.values.country}
            autoComplete={false}
            disableClearable
            getOptionLabel={(option: any) => (option.countryName ? option.countryName : '')}
            getOptionSelected={(option: any, value: any) => value.value === option.value}
            onChange={(event: inputEventType, value) => {
              setFieldValue('country', value);
              setSelectedGoogleOption(false);
              setMissingData({
                city: '',
                commune: '',
                isValidAddress: '',
                places: [],
                postalCode: '',
                region: '',
                streetNumber: '',
              });
            }}
            InputLabelProps={{
              shrink: true,
            }}
            renderInput={(params) => (
              <TextFieldMaterial
                {...params}
                className={classes.inputText}
                helperText={
                  formikContext.errors.country ? 'Selecciona un país de residencia' : null
                }
                error={formikContext.touched.country && Boolean(formikContext.errors.country)}
                variant="outlined"
                label="País de residencia"
              />
            )}
          />
        </Grid>

        {formikContext.values.country?.isoCode && formikContext.values.country?.isoCode !== 'CL' && (
          <>
            <Grid item xs={12}>
              <Field
                component={TextField}
                fullWidth
                placeholder={city.label}
                label={city.label}
                name={city.name}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Field
                component={TextField}
                fullWidth
                label={`${postalCode.label}`}
                name={postalCode.name}
                placeholder={postalCode.label}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} sm={12}>
          <Autocomplete
            options={addressOptions}
            freeSolo={false}
            disabled={formikContext.values.country?.countryName === null}
            value={formikContext.values.address}
            getOptionLabel={(option: any) => (option ? option : '')}
            onInputChange={(event: inputEventType, value) => handleAddressChange(value)}
            onChange={(event, value) => {
              if (value && addressOptions.includes(value)) {
                setSelectedGoogleOption(true);
              } else {
                setSelectedGoogleOption(false);
                formikContext.values.address = '';
              }
              if (value) {
                formikContext.values.address = value;
                handleAddressComponent(value);
              }
            }}
            onBlur={() => {
              if (addressOptions.includes(formikContext.values.address)) {
                setSelectedGoogleOption(true);
              } else {
                setSelectedGoogleOption(false);
                formikContext.values.address = '';
              }
            }}
            renderInput={(params) => {
              return (
                <TextFieldMaterial
                  {...params}
                  error={!selectedGoogleOption}
                  helperText={
                    !selectedGoogleOption ? (
                      <span style={{ color: '#fe5050' }}>Selecciona entre una de las opciones</span>
                    ) : (
                      ''
                    )
                  }
                  variant="outlined"
                  label="Dirección"
                />
              );
            }}
          />
          {formikContext.values.country?.isoCode &&
            !/\d/.test(formikContext.values.address) &&
            formikContext.values.address && (
              <>
                <Checkbox
                  checked={isCheckboxChecked}
                  onChange={() => {
                    setIsCheckboxChecked(!isCheckboxChecked);
                  }}
                  style={{ color: '#065072' }}
                  required={true}
                />
                <span style={{ color: '#065072' }}>Mi domicilio no tiene número</span>
              </>
            )}
          {isPostalCodeVisible && (
            <Grid item xs={12} sm={12}>
              <Field
                component={TextField}
                fullWidth
                label={`${postalCode.label}`}
                name={postalCode.name}
                placeholder={postalCode.label}
                style={{ marginTop: '10px' }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
              />
            </Grid>
          )}
        </Grid>

        {formikContext.values.country !== undefined &&
        formikContext.values.country?.id !== null &&
        formikContext.values.country?.isoCode !== 'CL' ? (
          <>
            <p className={classes.residenceSubtitle}>Información de facturación en Chile</p>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                options={billAddressOptions}
                freeSolo={false}
                disabled={formikContext.values.country?.countryName === null}
                value={formikContext.values.billAddress}
                getOptionLabel={(option: any) => (option ? option : '')}
                onInputChange={(event: inputEventType, value) => handleBillAddressChange(value)}
                onBlur={() => {
                  if (billAddressOptions.includes(formikContext.values.billAddress)) {
                    setSelectedGoogleOptionBillAddress(true);
                  } else {
                    setSelectedGoogleOptionBillAddress(false);
                    formikContext.values.billAddress = '';
                  }
                }}
                onChange={(event, value) => {
                  if (value && billAddressOptions.includes(value)) {
                    setSelectedGoogleOptionBillAddress(true);
                  } else {
                    setSelectedGoogleOptionBillAddress(false);
                    formikContext.values.billAddress = '';
                  }
                  if (value) {
                    formikContext.values.billAddress = value;
                    handleBillAddressComponent(value);
                  }
                }}
                renderInput={(params) => {
                  return (
                    <TextFieldMaterial
                      {...params}
                      error={!selectedGoogleOptionBillAddress}
                      helperText={
                        !selectedGoogleOptionBillAddress ? (
                          <span style={{ color: '#fe5050' }}>
                            Selecciona entre una de las opciones
                          </span>
                        ) : (
                          ''
                        )
                      }
                      variant="outlined"
                      label="Dirección"
                    />
                  );
                }}
              />
              {formikContext.values.country?.isoCode &&
                formikContext.values.country?.isoCode != 'CL' &&
                !/\d/.test(formikContext.values.billAddress) &&
                formikContext.values.billAddress && (
                  <>
                    <Checkbox
                      checked={isCheckboxCheckedBillAddress}
                      onChange={() => {
                        setIsCheckboxCheckedBillAddress(!isCheckboxCheckedBillAddress);
                      }}
                      style={{ color: '#065072' }}
                      required={true}
                    />
                    <span style={{ color: '#065072' }}>Mi domicilio no tiene número</span>
                  </>
                )}
            </Grid>
            {isChileanPostalCodeVisible && (
              <Grid item xs={12} sm={12}>
                <Field
                  component={TextField}
                  fullWidth
                  label={`${chileanPostalCode.label}`}
                  name={chileanPostalCode.name}
                  placeholder={chileanPostalCode.label}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                />
              </Grid>
            )}
          </>
        ) : null}
      </Grid>
    </Container>
  );
};

export default ContactInformation;
