import {
  IPersonalInformation1,
  IPersonalInformation2,
  IContactInformation,
  IDocumentInformation,
  IIdentification,
} from './personFormTypes';

export const PersonalSerialNumberModel: IIdentification = {
  identificationValue: {
    name: 'identificationValue',
    label: 'Rut',
  },
  serialNumber: {
    name: 'serialNumber',
    label: 'Número de documento',
  },
};

export const PersonalInformation1Model: IPersonalInformation1 = {
  nationality: {
    name: 'nationality',
    label: 'Nacionalidad',
  },
  birthDate: {
    name: 'birthDate',
    label: 'Fecha de nacimiento',
  },
  firstName: {
    name: 'firstName',
    label: 'Primer nombre',
  },
  secondName: {
    name: 'secondName',
    label: 'Segundo nombre',
  },
  identificationValue: {
    name: 'identificationValue',
    label: 'Rut',
  },
  firstLastName: {
    name: 'firstLastName',
    label: 'Apellido paterno',
  },
  secondLastName: {
    name: 'secondLastName',
    label: 'Apellido materno',
  },
  reason: {
    name: 'reason',
    label: 'Razón para utilizar CurrencyBird',
  },
  reasonLength: 0,
};

export const PersonalInformation2Model: IPersonalInformation2 = {
  nationality: {
    name: 'nationality',
    label: 'Nacionalidad',
  },
  pep: {
    name: 'pep',
    label: 'Pep',
  },
  gender: {
    name: 'gender',
    label: 'Sexo',
  },
  maritalStatus: {
    name: 'maritalStatus',
    label: 'Estado civil',
  },
  profession: {
    name: 'profession',
    label: 'Ocupación',
  },
  degree: {
    name: 'degree',
    label: 'Oficio',
  },
  employerName: {
    name: 'employerName',
    label: 'Empleador',
  },
  employerPosition: {
    name: 'employerPosition',
    label: 'Cargo',
  },
  economicActivityCode: {
    name: 'economicActivityCode',
    label: 'Categoría',
  },
  art150: false,
};

export const ContactInformationModel: IContactInformation = {
  phone: {
    name: 'phone',
    label: 'Teléfono de contacto',
  },
  country: {
    name: 'country',
    label: 'País de residencia',
  },
  city: {
    name: 'city',
    label: 'Ciudad',
  },
  commune: {
    name: 'commune',
    label: 'Comuna',
  },
  postalCode: {
    name: 'postalCode',
    label: 'Código postal',
  },
  address: {
    name: 'address',
    label: 'Dirección',
  },
  billAddress: {
    name: 'billAddress',
    label: 'Dirección de facturación en Chile',
  },
  addressValidation: false,
  billAddressValidation: false,
  chileanPostalCode: {
    name: 'chileanPostalCode',
    label: 'Código postal en Chile',
  },
  region: {
    name: 'region',
    label: 'Región',
  },
};
export const DocumentInformationModel: IDocumentInformation = {
  idExpirationDate: {
    name: 'idExpirationDate',
    label: 'Fecha de vencimiento del documento',
  },
};

export default {
  formId: 'personSignUpForm',
  PersonalSerialNumberModel,
  PersonalInformation1Model,
  PersonalInformation2Model,
  ContactInformationModel,
  DocumentInformationModel,
};
