import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import TextFieldMaterial from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { rutFormat, rutClean } from '../../../../utils/rutHelpers';
import APIProspect from '../../../../services/APIProspect.service';
import { useAuth } from '../../../../hooks/useAuth';
import { IIdentification } from '../personFormTypes';
import useStyles from './DniSerialNumber.styles';
import defaultDate from '../../../../utils/defaultDateCalendar';
import newDni from '../../../../images/Registration/dni_nueva.svg';
import oldDni from '../../../../images/Registration/dni_antigua.svg';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

const DniSerialNumber: React.FC<IIdentification> = (props) => {
  const { getBearer } = useAuth();
  const classes = useStyles();
  const { identificationValue, serialNumber } = props;

  const formikContext = useFormikContext<{
    identificationValue: string;
    serialNumber: string;
  }>();
  const { setFieldValue, values } = formikContext;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  useEffect(() => {
    APIProspect.defaults.headers.common['Authorization'] = getBearer();
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let currentRoute = window.location.pathname;
      window.addEventListener('popstate', (event) => {
        currentRoute = window.location.pathname;
      });
      const enviarRutaAGTM = () => {
        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'gatsby-route-change',
            ruta: currentRoute,
          });
        }
      };

      if (currentRoute !== '/signup-form/document-validation') {
        history.pushState({}, '', '/signup-form/document-validation');
        enviarRutaAGTM();
      }
    }
  }, []);

  return (
    <Container maxWidth="sm">
      <Grid className={classes.formProperties} container justifyContent="center" spacing={2}>
        <h4 className={classes.title}>
          Ingresa tu RUT y el número de tu documento de identidad chileno
        </h4>
        <p className={classes.hint}>
          Puedes registrarte aunque tu cédula de identidad esté vencida
          <Tooltip
            title="En este caso, te pediremos documentación adicional a través de correo electrónico"
            arrow
            open={tooltipOpen}
            onClose={handleTooltipClose}
            onOpen={handleTooltipOpen}
            classes={{ tooltip: classes.customTooltip }}
          >
            <IconButton
              size="small"
              className={classes.iconButton}
              onClick={() => {
                handleTooltipOpen();
              }}
              onTouchStart={() => {
                handleTooltipOpen();
              }}
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </p>
        <Grid item xs={12}>
          <Field
            component={TextField}
            fullWidth
            label="RUT o RUN"
            name={identificationValue.name}
            variant="outlined"
            placeholder="RUT o RUN"
            InputLabelProps={{
              shrink: true,
            }}
            value={rutFormat(formikContext.values.identificationValue)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('identificationValue', rutClean(event.currentTarget.value));
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={TextField}
            fullWidth
            label={serialNumber.label}
            name={serialNumber.name}
            variant="outlined"
            placeholder="Número de documento"
            InputLabelProps={{
              shrink: true,
            }}
            value={values.serialNumber}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('serialNumber', event.currentTarget.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: 'flex' }}>
            <img
              src={newDni}
              alt="Imagen 1"
              style={{ flex: 1, marginRight: '10px', width: '50%' }}
            />
            <img src={oldDni} alt="Imagen 2" style={{ flex: 1, width: '50%' }} />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DniSerialNumber;
