import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useAuth } from '../../../hooks/useAuth';
import { navigate } from 'gatsby';
import APIProspect from '../../../services/APIProspect.service';
import personFormModel from './personFormModel';
import personFormInitialValues from './personFormInitialValues';
import { FormikStep, FormikStepper } from './FormikStepper';
import DniSerialNumber from './DniSerialNumber/DniSerialNumber';
import PersonalInformation1 from './PersonalInformation/PersonalInformation1';
import PersonalInformation2 from './PersonalInformation/PersonalInformation2';
import ContactInformation from './ContactInformation/ContactInformation';
import DropDocumentFront from './DocumentForm/DropDocument/DropDocumentFront';
import DropDocumentBack from './DocumentForm/DropDocument/DropDocumentBack';
import PersonTermsAndConditions from './TermsAndCondition/PersonTermsAndConditions';
import {
  PersonalSerialNumberSchema,
  PersonalInformationSchema,
  PersonalInformation2Schema,
  ContactInformationSchema,
  DocumentSchema,
  Document2Schema,
} from './validation';
import TransitionAlert from '../../Common/Alert';
import * as Sentry from '@sentry/gatsby';

const PersonIndex = ({ state }: { state?: any }) => {
  const {
    PersonalSerialNumberModel: { identificationValue, serialNumber },
    PersonalInformation1Model: {
      nationality,
      birthDate,
      firstName,
      secondName,
      firstLastName,
      secondLastName,
      reason,
    },
    PersonalInformation2Model: {
      maritalStatus,
      profession,
      degree,
      economicActivityCode,
      employerName,
      employerPosition,
      pep,
      gender,
    },
    ContactInformationModel: {
      phone,
      country,
      city,
      commune,
      postalCode,
      address,
      addressValidation,
      billAddressValidation,
      billAddress,
      chileanPostalCode,
      region,
    },
  } = personFormModel;

  const {
    PersonalSerialNumberInitialValues,
    PersonalInformation1InitialValues,
    PersonalInformation2InitialValues,
    ContactInformationInitialValues,
    DocumentInitialValues,
  } = personFormInitialValues;

  const { getBearer } = useAuth();
  const publicIp = require('react-public-ip');

  const [data, setData] = useState({
    ...PersonalSerialNumberInitialValues,
    ...PersonalInformation1InitialValues,
    ...PersonalInformation2InitialValues,
    ...ContactInformationInitialValues,
    ...DocumentInitialValues,
  });

  const [reasonLength, setReasonLength] = useState(0);
  const [fetching, setFetching] = useState(true);
  const [step, setStep] = useState('');
  const [showPersonalInformationManualStep, setShowPesonalInformationManual] = useState(false);
  const [showSerialNumberStep, setShowSerialNumber] = useState(true);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [description, setDescription] = useState('Por favor, intenta nuevamente');
  const [showMaritalStatus, setShowMaritalStatus] = useState(false);
  const [errorCounter, setErrorCounter] = useState(0);
  const { isAuthenticated, getUserFromStorage, logout } = useAuth();
  const capitalizeFirstLetter = (string: any) => string.charAt(0).toUpperCase() + string.slice(1);
  const requiredAttributes = ['names', 'firstLastName', 'birthDate'];

  useEffect(() => {
    APIProspect.defaults.headers.common['Authorization'] = getBearer();
    const fetchData = async () => {
      const response = await APIProspect.get('signup/prospect');
      const prospectData = response.data;
      const updatedData = {
        ...data,
        ...response.data,
        profession: response.data.profession && capitalizeFirstLetter(response.data.profession),
      };
      updatedData.externalReport.maritalStatus === 'C' ? setShowMaritalStatus(true) : null;
      setData(updatedData);
      setFetching(false);
      if (!requiredAttributes.every((attr) => !!prospectData[attr])) {
        setShowPesonalInformationManual(true);
      }
      if (Object.keys(prospectData.externalReport).length !== 0) {
        setShowSerialNumber(false);
      }
    };
    fetchData();
  }, [step]);

  useEffect(() => {
    if (state) {
      setData(state);
    }
  }, []);

  const showIdentificationStep =
    !data.documents || !Array.isArray(data.documents) || data.documents.length < 2;
  const idExpirationDate = typeof data.idExpirationDate === 'string' ? data.idExpirationDate : '';
  const showTermsAndConditionStep = data.terms != true || !data.termsAt;
  const maritalStatusSection =
    (showMaritalStatus && data.gender == 'female') || data.maritalStatus == '';
  const genderSection = !data.gender || data.gender === '';
  const idExpirationSection = !data.idExpirationDate || data.idExpirationDate === '';

  const handleFirstStepValidation = async (values: any) => {
    try {
      const equifax = await APIProspect.post('/signup/personal-information-by-rut', values);
      setShowPesonalInformationManual(false);
      setStep('equifaxInformation');
    } catch (error: any) {
      Sentry.captureException(error);

      if (error.response.data.message == 'Cédula bloqueada') {
        setAlertOpen(true);
        setAlertTitle('Error con la cédula');
        setDescription(
          'Hay un problema con tu cédula de identidad, por favor, comunicate con el registro civil.'
        );
        throw new Error(error);
      }
      if (
        error.response.data.message === 'Rut inválido' ||
        error.response.data.message === 'Número de serie incorrecto'
      ) {
        if (errorCounter === 1) {
          setAlertOpen(true);
          setAlertTitle(error.response.data.message);
          setDescription('Último intento antes de cerrar sesión. Por favor, inténtalo nuevamente.');
          setErrorCounter(errorCounter + 1);
          throw new Error(error);
        } else if (errorCounter === 2) {
          logout();
        }
        setErrorCounter(errorCounter + 1);
        setAlertOpen(true);
        setAlertTitle(error.response.data.message);
        throw new Error(error);
      } else if (error.response.status != 200) {
        if (
          error.response.data.message == 'SERVICE_NOT_AVAILABLE' ||
          error.response.data.type == 'REQUEST_TIMEOUT' ||
          error.response.data.message == 'Datos del rut consultado no disponibles' ||
          error.response.data.message == 'Documento no vigente del rut consultado'
        ) {
          setShowPesonalInformationManual(true);
        } else {
          setAlertOpen(true);
          setAlertTitle(error.response.data.message);
          throw new Error(error);
        }
      }
    }
  };

  return (
    <>
      <Grid item xs={12} md={8} lg={8} style={{ margin: '0 auto' }}>
        {alertOpen && (
          <TransitionAlert
            setAlertOpen={setAlertOpen}
            open={alertOpen}
            title={alertTitle}
            description={description}
            severity="error"
          />
        )}
      </Grid>
      <FormikStepper
        enableReinitialize
        initialValues={{
          ...data,
        }}
        onSubmit={async (values) => {
          setStep('confirmTerms');
          const userIp = (await publicIp.v4()) || null;
          const confirmTerms = await APIProspect.post(
            '/signup/personal-information/confirm-terms',
            {
              ...values,
              userIp,
            }
          );
          if (confirmTerms.data == true && confirmTerms.status == 200) {
            navigate('/profilecomplete/');
          }
        }}
      >
        <FormikStep
          label="Validación documentación chilena"
          validationSchema={PersonalSerialNumberSchema}
          onSubmit={handleFirstStepValidation}
          setPreviousGlobalStep={false}
          setNextGlobalStep={true}
          fetching={fetching}
          next={showSerialNumberStep}
        >
          <DniSerialNumber identificationValue={identificationValue} serialNumber={serialNumber} />
        </FormikStep>
        <FormikStep
          label="Adjuntar documentación"
          validationSchema={DocumentSchema}
          setPreviousGlobalStep={true}
          setNextGlobalStep={false}
          next={showIdentificationStep}
          onSubmit={async (values) => {
            const formData = new FormData();
            formData.append('uuid', values.uuid);
            formData.append('front', values.documentFront);
            formData.append(
              'idExpirationDate',
              idExpirationDate ? idExpirationDate : values.idExpirationDate
            );
            await APIProspect.post('/signup/personal-information/documents/CL/front', formData);
            setStep('uploadBackDocument');
          }}
        >
          <DropDocumentFront idExpirationSection={idExpirationSection} />
        </FormikStep>
        <FormikStep
          label="Documentación"
          setPreviousGlobalStep={false}
          validationSchema={Document2Schema}
          setNextGlobalStep={true}
          next={showIdentificationStep}
          onSubmit={async (values) => {
            const formData = new FormData();
            formData.append('uuid', values.uuid);
            formData.append('back', values.documentBack);
            await APIProspect.post('/signup/personal-information/documents/CL/back', formData);
            setStep('personalInformation');
          }}
        >
          <DropDocumentBack />
        </FormikStep>
        {showPersonalInformationManualStep && (
          <FormikStep
            label="Datos personales"
            validationSchema={PersonalInformationSchema}
            onSubmit={async (values) => {
              values.names = `${values.firstName} ${values.secondName} `.trim();
              await APIProspect.post('/signup/personal-information/first', values);
              setStep('personalInformation');
            }}
            next={true}
            setPreviousGlobalStep={false}
            setNextGlobalStep={false}
            fetching={fetching}
          >
            <PersonalInformation1
              nationality={nationality}
              birthDate={birthDate}
              firstName={firstName}
              secondName={secondName}
              firstLastName={firstLastName}
              secondLastName={secondLastName}
              identificationValue={identificationValue}
              reason={reason}
              reasonLength={reasonLength}
            />
          </FormikStep>
        )}
        <FormikStep
          label="Información personal"
          validationSchema={PersonalInformation2Schema}
          setPreviousGlobalStep={true}
          setNextGlobalStep={true}
          next={true}
          onSubmit={async (values) => {
            await APIProspect.post('/signup/personal-information/second', values);
            setStep('contactInformation');
          }}
        >
          <PersonalInformation2
            maritalStatus={maritalStatus}
            gender={gender}
            profession={profession}
            degree={degree}
            employerName={employerName}
            employerPosition={employerPosition}
            economicActivityCode={economicActivityCode}
            pep={pep}
            maritalStatusSection={maritalStatusSection}
            genderSection={genderSection}
            nationality={nationality}
          />
        </FormikStep>
        <FormikStep
          label="Información de contacto"
          setPreviousGlobalStep={true}
          setNextGlobalStep={true}
          validationSchema={ContactInformationSchema}
          next={true}
          stepName={'info-contact'}
          fetching={fetching}
          onSubmit={async (values) => {
            const userIp = (await publicIp.v4()) || null;
            const contact = await APIProspect.post('/signup/contact', { ...values, userIp });
            setStep('finalStage');
          }}
        >
          <ContactInformation
            phone={phone}
            country={country}
            city={city}
            commune={commune}
            postalCode={postalCode}
            address={address}
            billAddress={billAddress}
            addressValidation={addressValidation}
            billAddressValidation={billAddressValidation}
            chileanPostalCode={chileanPostalCode}
            region={region}
          />
        </FormikStep>
        {showTermsAndConditionStep && (
          <FormikStep
            label="Términos y condiciones"
            setPreviousGlobalStep={true}
            setNextGlobalStep={false}
            stepName={'terms'}
            onSubmit={async (values) => {
              setStep('finalStage');
            }}
          >
            <PersonTermsAndConditions />
          </FormikStep>
        )}
      </FormikStepper>
    </>
  );
};

export default PersonIndex;
