import React, { useEffect } from 'react';
import { graphql, navigate } from 'gatsby';
import SEO from '../components/SEO';
import SignUpForm from '../components/Registration/Form/index';
import { Container, CircularProgress } from '@material-ui/core';
import { useAuth } from '../hooks/useAuth';

const RegisterPage = ({ location }: { location: any }, { data }: any) => {
  const { isAuthenticated, user, login } = useAuth();
  const params = new URLSearchParams(location.search);
  const hasAuthParams = params.has('accessToken') && params.has('type') && params.has('expiresIn');

  useEffect(() => {
    if (hasAuthParams) {
      const auth = {
        accessToken: params.get('accessToken') || '',
        type: params.get('type') || '',
        expiresIn: parseInt(params.get('expiresIn') || '0', 10),
      };
      login(auth, true);
    }
  }, [hasAuthParams, params, login]);

  if (!isAuthenticated() && typeof window !== 'undefined' && !hasAuthParams) {
    navigate('/');
    return null;
  }

  return (
    <>
      {user ? (
        <Container maxWidth={false} style={{ marginTop: 72, height: `auto`, background: '#FFF' }}>
          <SEO title="Formulario de registro" />
          <SignUpForm />
        </Container>
      ) : null}
    </>
  );
};

export default RegisterPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
