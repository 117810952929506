import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { Field, FieldAttributes, useFormikContext } from 'formik';
import { DatePicker } from 'formik-material-ui-pickers';
import { makeStyles, createTheme, ThemeProvider, Theme } from '@material-ui/core/styles';
import {
  DatePickerProps as MuiDatePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import InputAdornment from '@material-ui/core/InputAdornment';
import EventIcon from '@material-ui/icons/Event';
import customTheme from '../../theme';
import { format } from 'date-fns';
import { convertToLocalTime } from 'date-fns-timezone';
import esLocale from 'date-fns/locale/es';

const datePickerThemeOverride = createTheme({
  ...customTheme,
  palette: {
    primary: {
      main: '#074f71',
    },
  },
});

const useStyles = makeStyles((theme: Theme) => {
  return {
    inputAdornment: {
      outline: 'none',
    },
    inputIcon: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  };
});

export type DatePickerFieldProps = Omit<
  Partial<MuiDatePickerProps & FieldAttributes<Date>>,
  'valueOf'
> & {
  name: string;
};

const DatePickerField: React.FC<DatePickerFieldProps> = (props) => {
  const classes = useStyles();
  const { name, label, placeholder, onChange, ...rest } = props;

  const { setFieldValue } = useFormikContext();

  return (
    <ThemeProvider theme={datePickerThemeOverride}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <Field
          // static
          fullWidth
          component={DatePicker}
          inputVariant="outlined"
          format="dd/MM/yyyy"
          openTo="year"
          views={['year', 'month', 'date']}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" className={classes.inputAdornment}>
                <EventIcon className={classes.inputIcon} fontSize="medium" />
              </InputAdornment>
            ),
          }}
          // Dynamic
          name={name}
          label={label}
          placeholder={placeholder}
          onChange={
            onChange
              ? onChange
              : (value: Date) => {
                  const date = value;
                  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                  const localDate = convertToLocalTime(date, {
                    timeZone: timezone,
                  });
                  setFieldValue(name, format(localDate, 'yyyy/MM/dd'));
                }
          }
          {...rest}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default DatePickerField;
