import personFormModel from './personFormModel';

const {
  PersonalSerialNumberModel: { identificationValue, serialNumber },
  PersonalInformation1Model: {
    nationality,
    birthDate,
    firstName,
    secondName,
    firstLastName,
    secondLastName,
    reason,
  },
  PersonalInformation2Model: {
    pep,
    maritalStatus,
    gender,
    profession,
    degree,
    employerName,
    employerPosition,
    economicActivityCode,
    art150,
  },
  ContactInformationModel: {
    phone,
    address,
    country,
    city,
    commune,
    postalCode,
    billAddress,
    chileanPostalCode,
    region,
  },
  DocumentInformationModel: { idExpirationDate },
} = personFormModel;

export const PersonalSerialNumberInitialValues = {
  [identificationValue.name]: '',
  [serialNumber.name]: '',
};

export const PersonalInformation1InitialValues = {
  [nationality.name]: '',
  [birthDate.name]: null,
  [firstName.name]: '',
  [secondName.name]: '',
  [firstLastName.name]: '',
  [secondLastName.name]: '',
  [identificationValue.name]: '',
  [reason.name]: [],
};

export const PersonalInformation2InitialValues = {
  [pep.name]: '',
  [gender.name]: '',
  [maritalStatus.name]: '',
  [profession.name]: '',
  [degree.name]: '',
  [employerName.name]: '',
  [employerPosition.name]: '',
  [economicActivityCode.name]: {},
};

export const ContactInformationInitialValues = {
  [phone.name]: '',
  [country.name]: { id: null, isoCode: null, countryName: null },
  [city.name]: '',
  [commune.name]: '',
  [postalCode.name]: '',
  [region.name]: '',
  [address.name]: '',
  [billAddress.name]: '',
  [chileanPostalCode.name]: '',
};

export const DocumentInitialValues = {
  [idExpirationDate.name]: null,
};

export default {
  PersonalSerialNumberInitialValues,
  PersonalInformation1InitialValues,
  PersonalInformation2InitialValues,
  ContactInformationInitialValues,
  DocumentInitialValues,
};
