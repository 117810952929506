import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core';

export interface IDialog {
  open: boolean;
}

const useStyles = makeStyles(() => {
  return {
    dialog: {
      width: `100%`,
    },
  };
});

const DialogPep: React.FC<IDialog> = (props) => {
  const classes = useStyles();

  // getModalStyle is not a pure function, we roll the style only on the first render
  const { open } = props;
  const [show, setShow] = React.useState(false);
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    } else {
      if (show == true) {
        setShow(false);
      } else {
        setShow(true);
      }
    }
  }, [open]);

  const body = (
    <div>
      <h2 id="simple-modal-title">¿Qué es PEP?</h2>
      <p id="simple-modal-description">
        La Circular N° 49 de la UAF, del 3 de diciembre de 2012, define como Personas Expuestas
        Políticamente (PEP) a “los chilenos o extranjeros que desempeñan o hayan desempeñado
        funciones públicas destacadas en un país, hasta a lo menos un año de finalizado el ejercicio
        de las mismas”.
      </p>
      <p id="simple-modal-description">
        Se incluyen en esta categoría a jefes de Estado o de un Gobierno, políticos de alta
        jerarquía, funcionarios gubernamentales, judiciales o militares de alta jerarquía, altos
        ejecutivos de empresas estatales, así como{' '}
        <b>
          sus cónyuges, sus parientes hasta el segundo grado de consanguinidad, y las personas
          naturales con las que hayan celebrado un pacto de actuación conjunta{' '}
        </b>
        , mediante el cual tengan poder de voto suficiente para influir en sociedades constituidas
        en Chile. Así, en nuestro país, a lo menos deberán estar calificadas como PEP las siguientes
        personas, sin que este enunciado sea taxativo:.
      </p>
      <div className="cb-questions-list">
        <ul>
          <li> Presidente de la República.</li>
          <li> Senadores, diputados y alcaldes.</li>
          <li> Ministros de la Corte Suprema y Cortes de Apelaciones.</li>
          <li>
            {' '}
            Ministros de Estado, subsecretarios, intendentes, gobernadores, secretarios regionales
            ministeriales, embajadores, jefes superiores de Servicio, tanto centralizados como
            descentralizados, y el directivo superior inmediato que deba subrogar a cada uno de
            ellos.
          </li>
          <li>
            {' '}
            Comandantes en Jefe de las Fuerzas Armadas, director General de Carabineros, director
            General de Investigaciones, y el oficial superior inmediato que deba subrogar a cada uno
            de ellos.
          </li>
          <li> Fiscal Nacional del Ministerio Público y fiscales regionales.</li>
          <li> Contralor General de la República.</li>
          <li> Consejeros del Banco Central de Chile.</li>
          <li> Consejeros del Consejo de Defensa del Estado.</li>
          <li> Ministros del Tribunal Constitucional.</li>
          <li> Ministros del Tribunal de la Libre Competencia.</li>
          <li> Integrantes titulares y suplentes del Tribunal de Contratación Pública.</li>
          <li> Consejeros del Consejo de Alta Dirección Pública.</li>
          <li>
            {' '}
            Directores y ejecutivos principales de empresas públicas, según lo definido por la Ley
            Nº 18.045.
          </li>
          <li> Directores de sociedades anónimas nombrados por el Estado o sus organismos.</li>
          <li> Miembros de las directivas de los partidos políticos.</li>
        </ul>
      </div>
    </div>
  );

  return (
    <div>
      <Dialog
        open={show}
        className={classes.dialog}
        fullWidth
        maxWidth="md"
        onClose={() => setShow(false)}
      >
        <DialogContent>{body}</DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: '#074F71', color: '#FFF' }}
            variant="contained"
            onClick={() => setShow(false)}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogPep;
