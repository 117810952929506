const { validateRut, formatRut } = require('@currencybird/rut-helpers');

export const rutClean = (value: string): string => {
  if (typeof value === 'string') {
    return value
      .replace(/[^0-9kK]+/g, '')
      .replace(/^0+/, '')
      .toUpperCase();
  }
  return '';
};

export const rutValidate = (value: string): boolean => {
  return validateRut(value);
};

export const rutFormat = (value: string): string => {
  return formatRut(value);
};
