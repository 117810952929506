import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      color: `#f44336`,
      fontSize: `0.75rem`,
      marginLeft: 14,
    },
  })
);

function TextError(props) {
  console.log(props);
  const classes = useStyles();
  return <div className={classes.error}>{props.children}</div>;
}
export default TextError;
