import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      width: `100%`,
      color: `#074F71`,
      textAlign: `center`,
      margin: 0,
      fontSize: 18,
      marginTop: 15,
      [theme.breakpoints.down(501)]: {
        fontSize: 14,
      },
      // lineHeight:`52px`,
    },
    helperText: {
      color: 'red',
    },
    subtitle: {
      width: `100%`,
      color: `#074F71`,
      textAlign: `center`,
      marginTop: 0,
      [theme.breakpoints.down(501)]: {
        fontSize: 11,
      },
    },
    economicActivitySubtitle: {
      width: `100%`,
      color: `#074F71`,
      textAlign: `left`,
      paddingLeft: 12,
      paddingTop: 10,
      marginTop: 0,
      fontSize: 18,
      marginBottom: 2,
    },
    formProperties: {
      '& label': {
        [theme.breakpoints.down(501)]: {
          fontSize: 13,
        },
        color: `#074f71`,
      },
      '& fieldset': {
        borderColor: `#074f71`,
      },
      [theme.breakpoints.down(501)]: {
        '& input': {
          fontSize: 13,
        },
      },
    },
    helperText: {
      color: `grey`,
      cursor: `pointer`,
    },
    infoIcon: {
      paddingTop: 5,
      width: 15,
      display: `none`,
    },
    poppers: {
      width: `100%`,
      zIndex: 1,
      backgroundColor: '#fff',
      // position:`sticky`,
      '& ul': {
        fontSize: 15,
        textTransform: `capizalize`,
        backgroundColor: '#fff',
      },
    },
  })
);
export default useStyles;
