const defaultDateCalendar = (): string => {
  const dateNow = new Date();
  const year = 1990;
  const monthJs = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
  const month = // Setting current Month number from current Date object
    monthJs.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
      ? `0${monthJs}`
      : monthJs;
  const date =
    dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const defaultDate = `${year}-${month}-${date}`; // combining to format for defaultValue or value attribute of material <TextField>

  return defaultDate;
};

export default defaultDateCalendar;
