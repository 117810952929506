import React, { useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { TextField } from 'formik-material-ui';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import TextFieldMaterial from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { rutFormat, rutClean } from '../../../../utils/rutHelpers';
import APIProspect from '../../../../services/APIProspect.service';
import { useAuth } from '../../../../hooks/useAuth';
import FormikSelect from '../../../FormikFields/FormikSelect';
import DatePickerField from '../../../Common/DatePickerField';
import { IPersonalInformation1 } from '../personFormTypes';
import { ICountryData } from '../../../../types';
import useStyles from './PersonalInformation.styles';
import defaultDate from '../../../../utils/defaultDateCalendar';

const PersonDocumentation: React.FC<IPersonalInformation1> = (props) => {
  const { getBearer } = useAuth();
  const classes = useStyles();
  const {
    birthDate,
    firstName,
    secondName,
    firstLastName,
    secondLastName,
    identificationValue,
    reasonLength,
  } = props;
  const [nationalities, setNationalities] = React.useState([]);

  const maxDate = new Date();
  const currentDate = new Date();
  maxDate.setFullYear(currentDate.getFullYear() - 18);

  const formikContext = useFormikContext<{
    nationality: ICountryData;
    reason: string[];
    identificationValue: string;
  }>();
  const { setFieldValue, values } = formikContext;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let currentRoute = window.location.pathname;
      window.addEventListener('popstate', (event) => {
        currentRoute = window.location.pathname;
      });
      const enviarRutaAGTM = () => {
        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'gatsby-route-change',
            ruta: currentRoute,
          });
        }
      };

      if (currentRoute !== '/signup-form/personal-information-fallback') {
        history.pushState({}, '', '/signup-form/personal-information-fallback');
        enviarRutaAGTM();
      }
    }
  }, []);

  useEffect(() => {
    APIProspect.defaults.headers.common['Authorization'] = getBearer();
    APIProspect.get('signup/nationalities').then((response) => {
      setNationalities(response.data);
    });
  }, []);

  return (
    <Container maxWidth="sm">
      <Grid className={classes.formProperties} container justifyContent="center" spacing={2}>
        <h4 className={classes.title}>Completa tu información personal</h4>
        <p className={classes.subtitle}> Paso 1/2 </p>
        <Grid item xs={12}>
          <Field
            fullWidth
            component={TextField}
            label={firstName.label}
            name={firstName.name}
            placeholder={firstName.label}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            fullWidth
            component={TextField}
            label={secondName.label}
            name={secondName.name}
            placeholder={secondName.label}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            helperText="Dejar en blanco en caso de no tener segundo nombre"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={TextField}
            fullWidth
            label={firstLastName.label}
            placeholder={firstLastName.label}
            name={firstLastName.name}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={TextField}
            fullWidth
            label={secondLastName.label}
            name={secondLastName.name}
            placeholder={secondLastName.label}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            options={nationalities}
            disableClearable
            value={formikContext.values.nationality}
            getOptionLabel={(option: ICountryData) =>
              option.countryName ? option.countryName : ''
            }
            getOptionSelected={(option: ICountryData, value: ICountryData) =>
              value.isoCode === option.isoCode
            }
            onChange={(event: React.ChangeEvent<Record<string, unknown>>, value: ICountryData) =>
              setFieldValue('nationality', value)
            }
            renderInput={(params) => (
              <TextFieldMaterial
                {...params}
                helperText={formikContext.errors.nationality ? 'Seleccionar nacionalidad' : null}
                error={
                  formikContext.touched.nationality && Boolean(formikContext.errors.nationality)
                }
                variant="outlined"
                label="Nacionalidad"
                placeholder="Nacionalidad"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid>
            <DatePickerField
              name={birthDate.name}
              label={birthDate.label}
              maxDate={maxDate}
              placeholder="Fecha de nacimiento"
              initialFocusedDate={defaultDate()}
              disableFuture
            />
          </Grid>
        </Grid>
        {!formikContext.values.identificationValue && (
          <Grid item xs={12}>
            <Field
              component={TextField}
              fullWidth
              label="RUT o RUN"
              name={identificationValue.name}
              variant="outlined"
              placeholder="RUT o RUN"
              InputLabelProps={{
                shrink: true,
              }}
              value={rutFormat(values.identificationValue)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFieldValue('identificationValue', rutClean(event.currentTarget.value));
              }}
            />
          </Grid>
        )}
        {formikContext.values.reason.length === 0 && (
          <Grid item xs={12}>
            <FormControl variant="outlined" style={{ width: '100%' }}>
              <InputLabel htmlFor="outlined-age-native-simple">
                Razón para utilizar CurrencyBird
              </InputLabel>
              <Select
                labelId="selectReasonLabel"
                multiple
                name="reason"
                value={formikContext.values.reason}
                label="Razón para utilizar CurrencyBird"
                onChange={formikContext.handleChange}
                renderValue={(reason: string[]) => reason.join(', ')}
                MenuProps={MenuProps}
                inputProps={{
                  name: 'reason',
                  id: 'input-checkbox',
                }}
              >
                {reasonOptions.map((option) => (
                  <MenuItem id="menuItem" key={option} value={option}>
                    <ListItemIcon>
                      <CustomColorCheckbox
                        checked={formikContext.values.reason.indexOf(option) > -1}
                      />
                    </ListItemIcon>
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText className={classes.helperText}>
                {formikContext.touched.reason && formikContext.errors.reason
                  ? 'Selecciona una razón'
                  : null}
              </FormHelperText>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default PersonDocumentation;

const MenuProps = {
  PaperProps: {
    style: {
      height: `auto`,
      width: 400,
    },
  },
};

const CustomColorCheckbox = withStyles({
  root: {
    padding: '3px',
    '&$checked': {
      color: '#074F71',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const reasonOptions = [
  'Pago proveedor o servicio',
  'Pago estudios en el extranjero',
  'Ayuda familiar',
  'Inversiones y ahorros',
  'Pago de remuneraciones o sueldos',
];
